import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Layout from '../components/organisms/layout';
import SEO from '../components/organisms/seo';
import { ChatInfo, ChatLink } from '../components/atoms/chatText';
import { ChatRichtext } from '../components/atoms/chatRichtext';
import ChatLeft from '../components/atoms/chatLeft';
import { ChatIllu } from '../components/atoms/chatMedia';
import Person from '../assets/svg/person.svg';
import Fahrer from '../assets/svg/radfahrer.svg';

const TeamPage = ({ data }) => {
  useEffect(() => {
    document.body.classList.add('pink');
    return () => document.body.classList.remove('pink');
  });

  const { team } = data.gradients.nodes[0];

  return (
    <Layout bgSrc={team.image.asset.fluid}>
      <SEO siteTitle="Team" />
      <div className="chats" style={{ paddingBottom: 'var(--spacing-L)' }}>
        <ChatLeft text="Christian Holler" />
        <ChatInfo text="Autor" />
        <ChatRichtext>
          <p>
            Christian ist Professor für Ingenieurmathematik an der Fakultät für
            angewandte Naturwissenschaften und Mechatronik, Hochschule München.
          </p>
          <p>
            Zuvor hat er in experimenteller Astrophysik an der Universität
            Cambridge promoviert und später zu diesen Themen in Oxford
            geforscht. Seit einigen Jahren beschäftigt er sich intensiv mit
            erneuerbaren Energien und der öffentlichen Diskussion darüber. Seit
            2021 ist er zudem Innovationsprofessor für Lehre, um den
            interdisziplinären Schwerpunkt Nachhaltigkeit weiter auszubauen und
            neue Lehrangebote zu schaffen.
          </p>
        </ChatRichtext>
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <motion.div
          animate={{ x: [0, 100, 0] }}
          transition={{ repeat: Infinity, duration: 4 }}
        >
          <ChatIllu>
            <Person />
          </ChatIllu>
        </motion.div>

        <ChatLeft text="Joachim Gaukel" />
        <ChatInfo text="Autor" />
        <ChatRichtext>
          <p>
            Joachim ist Mathematiker mit Stationen in Stuttgart und Darmstadt.
            Nach einigen Jahren in der Versicherungsbranche ist er seit über
            zehn Jahren Professor an der Hochschule Esslingen. Neben Vorlesungen
            zur Mathematik hält er auch Vorlesungen über erneuerbare Energien.
            Ein nachhaltiger Lebensstil ist ihm schon immer ein Anliegen
            gewesen. Zu Hause auf dem Dach befindet sich eine Fotovoltaik- und
            Solarthermie-Anlage. Und die Fahrten zum Arbeitsplatz hat er
            zeitlebens fast immer mit öffentlichen Verkehrsmitteln oder mit dem
            Fahrrad zurückgelegt.
          </p>
        </ChatRichtext>
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <ChatLeft text="Harald Lesch" />
        <ChatInfo text="Autor" />
        <ChatRichtext>
          <p>
            Harald ist Professor für theoretische Astrophysik an der
            Ludwig-Maximilians-Universität München und einer der bekanntesten
            Naturwissenschaftler Deutschlands. Er vermittelt seit vielen Jahren
            der breiten Öffentlichkeit spannendes naturwissenschaftliches
            Wissen. Er hat zahlreiche Bücher veröffentlicht, in denen er sich
            neben vielen weiteren Themen intensiv mit unserer Welt von morgen
            auseinandersetzt. Im ZDF moderiert er unter anderem die beliebte
            Sendung „Leschs Kosmos“.
          </p>
        </ChatRichtext>
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <ChatLeft text="Florian Lesch" />
        <ChatInfo text="Autor" />
        <ChatRichtext>
          <p>
            Florian ist Ingenieur für erneuerbare Energien und Energietechnik.
            Nach dem Bachelorstudium hat er sich im Rahmen seines Masterstudiums
            mit der Erforschung der Weiterverwertung von Abwärme beschäftigt.
            Nach dem Abschluss arbeitete er selbstständig im Bereich der
            Gebäudeenergieberatung, Fotovoltaik-Anlagenplanung und am Thema
            Mieterstrom.
          </p>
          <p>
            Seit 2021 ist er Energie- und Klimaschutzbeauftragter in einer
            Münchner Landkreisgemeinde. Florian Lesch interessiert und
            beschäftigt sich seit Jahren intensiv mit Klimaschutz und der
            Energiewende.
          </p>
        </ChatRichtext>
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <ChatLeft text="Charlotte Kelschenbach" />
        <ChatInfo text="Illustration" />
        <ChatRichtext>
          <p>
            Sie untersuchte in ihrer Bachelorarbeit, mit welcher Bildmechanik
            wissenschaftliche Zusammenhänge logisch und emotional erschließbar
            werden. Ihr Interesse gilt dabei der Verbindung zwischen
            theoretischen Fragestellungen und Bildgestaltung.
          </p>
          <p>
            Charlotte ist selbstständige Illustratorin und
            Kommunikationsdesignerin mit eigenen Veröffentlichungen und arbeitet
            derzeit in den Lebenshilfe-Werkstätten sowie für die Stiftung
            Digitale Bildung.
          </p>
        </ChatRichtext>
        <ChatLink
          text="Schreibe ihr"
          link="http://www.charlottekelschenbach.de/"
        />
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <motion.div
          animate={{ x: [0, 100, 0] }}
          transition={{ repeat: Infinity, duration: 4 }}
        >
          <ChatIllu>
            <Fahrer />
          </ChatIllu>
        </motion.div>

        <ChatLeft text="Manuel Lorenz" />
        <ChatInfo text="Gestaltung" />
        <ChatRichtext>
          <p>
            Er untersuchte in seiner Bachelorarbeit, durch welche
            typographischen Mittel verschiedene kommunikative Ebenen so
            miteinander verbunden werden können, dass komplexe Inhalte
            verständlich dargestellt werden. Seine Gestaltung ist bewusst
            zurückhaltend, sodass die Illustration unterstützt und gleichzeitig
            der Sachbuchcharakter betont wird.
          </p>
          <p>
            Manuel ist Ausbilder und Dozent für Druck und Gestaltung an der
            Stiftung ICP und der Deutschen Journalistenschule.
          </p>
        </ChatRichtext>
        <ChatLink text="Schreibe ihm" link="https://www.manuel-lorenz.de/" />
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <ChatLeft text="Anna Ehrnsperger" />
        <ChatInfo text="Webdesign- & entwicklung" />
        <ChatRichtext>
          <p>
            Sie beschäftigte sich in ihrer Bachelorarbeit mit Gestaltung im
            digitalen Kontext und setzte sich mit der Frage auseinander, wie man
            zentrale Inhalte eines Sachbuches medienübergreifend darstellen
            kann, um wissenschaftliche Inhalte und Zusammenhänge auf
            spielerische Art und Weise erlebbar zu machen.
          </p>
          <p>
            Anna ist selbstständige UX/UI-Designerin und leidenschaftliche
            Webentwicklerin.
          </p>
        </ChatRichtext>
        <ChatLink text="Schreibe ihr" link="https://www.annaehrnsperger.de/" />
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <ChatLeft text="Anna Lino Roeßle" />
        <ChatInfo text="Gestaltung" />
        <ChatRichtext>
          <p>
            hat ebenfalls an der Fakultät für Design der Hochschule München
            Kommunikationsdesign studiert.
          </p>
          <p>
            Sie hat das Team nicht nur bei der Umsetzung intensiv unterstützt,
            sondern im Anschluss an die Bachelorarbeiten auch eigene
            Gestaltungsideen in das Projekt eingebracht. Ihr beruflicher
            Schwerpunkt liegt in den Bereichen Konzeption und Buchgestaltung. In
            ihrer Arbeit setzt sie sich intensiv mit den Themen Design und
            Didaktik auseinander.
          </p>
        </ChatRichtext>
        <ChatLink text="Schreibe ihr" link="https://www.annalino.de/" />
        <div style={{ paddingBottom: 'var(--spacing-XL)' }} />

        <ChatLeft text="Xuyen Dam" />
        <ChatInfo text="Betreuung" />
        <ChatRichtext>
          <p>
            Xuyen ist seit 2010 Professor für Typografie und
            Kommunikationsdesign an der Fakultät für Design, Hochschule München.
            2011 realisierte er das Typografie-Symposium 20PlusX. Neben der
            Lehre kuratierte und gestaltete er mit Teams von Studierenden das
            DOC. (Fakultätsmagazin) und das mhm (Magazin der Hochschule München)
            und 2021 das Buch 200 Jahre Hochschule München.
          </p>
        </ChatRichtext>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Team {
    gradients: allSanityGradients {
      nodes {
        team {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

TeamPage.propTypes = {
  data: PropTypes.object,
};

export default TeamPage;
